    import React, { useEffect, useMemo, useState } from 'react';
import {useNavigate} from "react-router-dom";
import { useParamId, useParamRole } from "../../useParam";
import { ILicenceCandidate, ILicenceOrder } from "../../../api/models";
import { LicenceService } from "../../../api/licence";
import { CacheService, IMAGE_URL } from "../../../api";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { ItemNav } from "../../form/ItemNav";
import { faCircleCheck, faPaperPlane, faRemove } from "@fortawesome/free-solid-svg-icons";
import {BackButton, FD, Input, Item, Segment } from "../../form";
import styles from "./licence_detail_candidates.module.scss";
import classNames from "classnames";
import AvatarImage from "../../foundation/AvatarImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPageLinkProps } from "../../page.props";
import {Link} from "react-router-dom";
import {toaster} from "../../../core/toaster";
import {ProfileService} from "../../../api";
import { GroupAdminRoles } from "../../../util/roles";
import printStyles from '../../../theme/print.module.scss';


export const LicenceDetailCandidates: React.FC<IPageLinkProps> = ({ user, profile, gotoUrl, refresh, setRefresh }) => {
    const role = useParamRole(user)
    const [loading, setLoading] = useState(true)
    const [candidates, setCandidates] = useState<ILicenceCandidate[]>([])
    const [search, setSearch] = useState<string>('')
    const [filterState, setFilterState] = useState<number>(1)
    const [order, setOrder] = useState<ILicenceOrder | null>(null)
    const navigate = useNavigate();
    const kyuUrl = `/${role?.orgId}/${role?.funkMgId}/kyu/`;
    const id = useParamId()


    const adminRoles = useMemo(() => GroupAdminRoles(user), [user]);

    useEffect(() => {
        LicenceService.get(role?.orgId || 0, id || 0).then((o) => {
            if (order?.id != null) {
                //console.log('++order == null++', order)
                if (o.orgId === role?.orgId) {  // self order
                    //console.log('orgId === role?.orgId')
                    //setFilterState(2);
                    if (o.status == 'submitted') {
                        setFilterState(1);
                    } else if (o.status == 'approved') {
                        //console.log('status===approved', o.status === 'approved')
                        setFilterState(1);
                        //console.log('filterState-approved', filterState)
                        // } else if (o.status != 'created') {
                        //     setFilterState(1);
                    } else {
                        setFilterState(2) // LV
                    }
                } else {
                    //console.log('orgId != role?.orgId')
                    setFilterState(1) // LV
                }
            }else {
                //console.log('**order == null**')
                setFilterState(2); // fresh start
            }
            setOrder(o)
            //console.log('filterState-end', filterState)
        })
    }, [role, id, candidates, order]);

    // useEffect(() => {
    //   console.log('filterState updated:', filterState);
    // }, [filterState]);

    useEffect(() => {
        refreshJudoCards(true)
    }, [user, id])

    useEffect(() => {
      if (order?.status === 'approved') {
        setFilterState(1);
        //console.log('Setting filterState to 1 for approved status');
      }
    }, [order]);

    const filter = useMemo(() => {
        return candidates.filter(x =>
            (x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                x.surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                `${x.mgId}`.includes(search)) &&
            (filterState === 0 ||
                (filterState === 1 ? x.ordered :
                    filterState === 2 ? (!x.ordered || x.ordered) && !x.errorCode : !x.errorCode)));
    }, [candidates, filterState, search]);

    const refreshJudoCards = async (force: boolean) => {
        setLoading(true);
        if (force) await CacheService.clear();
        if (role != null) {
            setCandidates(await LicenceService.candidates(role.orgId, id || 0))
        }
        setLoading(false);
    }

    const locked = order?.status !== 'created'
    const select = async (candidate: ILicenceCandidate) => {
        if ((!candidate.errorCode || candidate.ordered) && !locked && (candidate?.orgId === role?.orgId)) {
            await LicenceService.update(role.orgId, id || 0, candidate.mgId, candidate.ordered);
            await refreshJudoCards(true);
            setRefresh && setRefresh((refresh || 0) + 1);
            setFilterState(2);
        }
    };

    const renderFilterOptions = (): { label: string; value: number; }[] => {
        if (order !== null && role?.orgId == order.orgId) {
            return [
                { label: 'alle', value: 0 },
                { label: 'ausgewählte', value: 1 },
                { label: 'ausgewählte & verfügbare', value: 2 }
            ];
        }
        return [
            { label: 'alle', value: 0 },
            { label: 'ausgewählte', value: 1 },
            { label: 'ausgewählte & verfügbare', value: 2 }
        ];
    };

    const submit = () => {
        toaster(async () => {
                await LicenceService.approve(role.orgId, id||0)
                setRefresh && setRefresh((refresh ||0)+1);
                return
            }, {
                success: "Bestellung freigegeben",
                failure: "Fehler beim Freigeben"
            }, () => navigate(`${gotoUrl}/`)

        )
    }

    const goToKyu = async () => {
        interface AdminRole {
            form: string;
            orgId: number;
            orgName: string;
            funkMgId: number;
            orgLevel: number;
            functionName: string;
        }
        // First find the matching organization key
        const matchingOrgKey = Object.keys(adminRoles).find(key =>
            adminRoles[key].some(r =>
                r.form === 'kyu' && r.orgId === role?.orgId
            )
        );
        // Then find the KYU role in that organization's roles
        const kyuRole = matchingOrgKey ?
            adminRoles[matchingOrgKey].find(r =>
                r.form === 'kyu' && r.orgId === role?.orgId
            )
            : null;

        if (kyuRole) {
            navigate(`/${role?.orgId}/${kyuRole.funkMgId}/kyu/${order?.kyuExamId}?t=candidates`);
        } else {
            console.error('No KYU role found for this user');
        }
    }

    return (
        <div>
            <div className={printStyles['hide-for-print']}>
                <BackButton label={'zur Übersicht'} navigateTo={gotoUrl}/>
            </div>
            <ItemNavGroup
                label={`Bestellung Nr. ${order?.id} ${order?.kyuExamId ? ` von KYU:${order?.kyuExamId}` : ''}`}>
                {order?.orgId ? (
                    <>
                        {/*order.canSubmit && */order.items > 0 && order.status === 'created' && order.orgId === role?.orgId && (
                            <ItemNav
                                className={styles.submit}
                                primary={true}
                                align="center"
                                label={`Bestellung #${order.id} abschicken`}
                                //icon={faPaperPlane}
                                href={`${gotoUrl}/${id}?t=submit`}
                            />
                        )}
                        {(order.canApprove && order.items > 0 && order.status === 'submitted' && role.orgLevel < 3) && (
                            <ItemNav
                                className={styles.approve}
                                primary={true}
                                align="center"
                                label={`Bestellung #${order.id} freigeben`}
                                //icon={faPaperPlane}
                                //href={`${gotoUrl}/${id}?t=approve`}
                                onClick={submit}
                            />
                        )}
                        {(order.status === 'created' || order.status === 'submitted') && (
                            <ItemNav
                                className={classNames(
                                    styles.delete,
                                    {[styles.disabled]: !!order?.kyuExamId}
                                )}
                                primary={true}
                                align="center"
                                label={!order?.kyuExamId ?
                                    `Bestellung #${order.id} Löschen` :
                                    `\u26D4 Bestellung/KYU #${order.id} nicht löschbar!`}
                                //icon={faRemove}
                                href={`${gotoUrl}/${id}?t=delete`}
                                disabled={!!order?.kyuExamId}
                            />
                        )}
                    </>
                ) : ''}
                {order?.kyuExamId &&
                    <ItemNav
                        className={styles.submittedkyu}
                        primary={true}
                        align="center"
                        label={`KYU-Prüfung #${order?.kyuExamId} öffnen`}
                        onClick={goToKyu}
                    />
                }
                <Item type="full" className={styles.search}>
                    <Input onChange={setSearch} className={styles.name} value={search}/>
                </Item>

                {order?.status === 'created' ?
                    <Segment
                        className={styles.filter}
                        value={filterState}
                        onChange={setFilterState}
                        options={renderFilterOptions()}
                    />
                    : ''}

                {filter?.map(jc => {
                    let cardClassName = styles.judocard;

                    if (jc.ordered) {
                        if (order?.status === 'approved') {
                            cardClassName = classNames(styles.judocard, styles.active);
                        } else if (order?.status === 'submitted') {
                            if (order?.kyuExamId) {
                                cardClassName = classNames(styles.judocard, styles['submitted-by-kyu']);
                            } else {
                                cardClassName = classNames(styles.judocard, styles.submitted);
                            }
                        } else if (order?.status === 'created') {
                            cardClassName = classNames(styles.judocard, styles.created);
                        } else {
                            cardClassName = classNames(styles.judocard, styles.active);
                        }
                    }
                    console.log('++cardClassName++', order?.status, cardClassName);
                    return (
                        <div className={cardClassName} key={jc.mgId}>
                            <div className={styles.card} onClick={() => select(jc)}>
                                <div className={styles.imageContainer}>
                                    <AvatarImage
                                        className={styles.image}
                                        src={IMAGE_URL + jc?.uuid + `?v=${(Date.now() / 1000000).toFixed(0)}`}
                                    />
                                </div>
                                <div className={styles.content}>
                                    <div className={styles.text}>
                                        <div className={styles.title}>{jc.surname} {jc.firstname} / {jc.mgId}</div>
                                        <div className={styles.subtitle}>Geb.Datum: {FD(jc.birthdate)}</div>
                                    </div>
                                    {order?.orgId === role?.orgId && order?.status !== 'approved' && order?.status !== 'submitted' && jc.errorMessage && (
                                        <div className={styles.error}>{jc.errorMessage}</div>
                                    )}
                                    {jc.ordered && <div className={styles.selected}></div>}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </ItemNavGroup>
        </div>
    );
};