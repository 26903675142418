import React, {useState} from 'react';
import {useParamId, useParamRole} from "../../../../../components/useParam";
import {LicenceDetail} from "../../../../../components/admin/licences";
import {IPageProps} from "../../../../../components/page.props";
import Licence from "../../../../../components/admin/licences/licence";
import {withFilter} from "../../../../../api/withFilter";
import moment from 'moment';


const AppAdminLicences: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/more/admin/${role?.funkMgId}/licences`;
    const [refresh, setRefresh] = useState<number>(0);

    // Add state for date filtering
    const [dateFrom, setDateFrom] = useState<Date|null>(moment().subtract(1, 'year').toDate());
    const [dateTo, setDateTo] = useState<Date|null>(moment().add(1, 'month').toDate());
    const [filteredOrders, setFilteredOrders] = useState<any[]>([]);

    const id = useParamId();

    return id == null ? (
        <Licence
            {...props}
            gotoUrl={gotoUrl}
            refresh={refresh}
            onOrdersFiltered={(orders: any[]) => setFilteredOrders(orders)}
        />
    ) : (
        <LicenceDetail
            {...props}
            gotoUrl={gotoUrl}
            refresh={refresh}
            setRefresh={setRefresh}
            filteredOrders={filteredOrders}
        />
    );
};

export default withFilter('Judocard', {}, AppAdminLicences, undefined, -1);