import React, {useEffect, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import styles from "./member_detail.module.scss";
import {Duplicate} from "../../member/duplicate";
import {Button, DateInput, Form, Input, Item, PDS, Select} from "../../form";
import AvatarImage from "../../foundation/AvatarImage";
import {MemberService} from "../../../api/member";
import CameraButton from "../../foundation/camera_button";
import {IMember, IMemberDuplicate} from "../../../api/models/MemberModel";
import {useNavigate} from "react-router-dom";
import {useParamId, useParamRole} from "../../useParam";
import {toaster} from "../../../core/toaster";
import {CacheService} from "../../../api";
import {Loading} from "../../foundation/Loading";


export const MemberDetailGeneral: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const [loading, setLoading] = useState(true);
    const [member, setMember] = useState<IMember | null>();
    const [duplicates, setDuplicates] = useState<IMemberDuplicate[] | null>(null)
    const [selectedDuplicate, setSelectedDuplicate] = useState<IMemberDuplicate | null>(null)


    const navigate = useNavigate();
    const role = useParamRole(user);
    const id = useParamId();
    useEffect(() => {
        // console.log('argh', id)
        if (user) {
            setLoading(true);
            if (id) {
                MemberService.get(role.orgId, id).then((m) => {
                    setMember(m);
                    setDuplicates(null);
                    setLoading(false);
                });
            } else {
                setMember({
                    email: '',
                    surname: '',
                    birthdate: "",
                    city: "",
                    country: 3,
                    doorNo: "",
                    firstname: "",
                    gender: 'm',
                    houseNo: "",
                    middlename: "",
                    mobile: "",
                    stair: "",
                    street: "",
                    title: "",
                    uuid: "",
                    nationality: 500,
                    weightClass: 0,
                    zip: 0
                });
                setDuplicates(null);
                setLoading(false);
            }
        }
    }, [user, id]);

    const setMemberParam = (param: keyof IMember) => {
        return (value: any) => {
            let m = member || {} as unknown as IMember;

            // @ts-ignore
            m[param] = value;
            setMember(Object.assign({}, m));
        }
    }

    const assignMembership = (m: IMemberDuplicate) => {
        //console.log(m)
        toaster(async () => {
            await MemberService.assignRoleSimple(role.orgId, m.id, 'MEMBERSHIP');
            navigate(`${gotoUrl}`);
        })
    }

    const onSubmit = (evt: any) => {
        evt.preventDefault();
        setLoading(true);
        if (member) {
            if (member.id) {
                toaster(async () => {
                    setMember(await MemberService.update(role.orgId, member));
                    await CacheService.clear();
                    setRefresh && setRefresh((refresh || 0) + 1)
                }, {
                    success: "Mitglied aktualisiert",
                    failure: "Speichern fehlgeschlagen"
                }, () => {
                    setLoading(false);
                });
            } else {
                toaster(async () => {
                    const m = await MemberService.create(role.orgId, member)
                    if (m.created) {
                        setMember(m.created);
                        await CacheService.clear();
                        navigate(`${gotoUrl}/${m.created.id}`)
                        setRefresh && setRefresh((refresh || 0) + 1)
                    } else if ((m.duplicates?.length || 0) > 0) {
                        setDuplicates(m.duplicates || [])
                        throw new Error('found duplicates')
                    }
                }, {
                    success: "Mitglied hinzugefügt",
                    failure: "Speichern fehlgeschlagen"
                }, () => {
                    setLoading(false);
                });
            }
        }
        //console.log(evt);
        return false;
    }

    const anyAssignable = (duplicates?.filter(x => x?.assignable)?.length || 0) > 0

    const currentYear = new Date(Date.now()).getFullYear()
    //console.log(profile)

    const disabled = !!(role.orgId > 10 && member?.id) ;
    const verstorben = member?.verstorben == 1;

    return (
        <>
            {loading && <Loading/>}
            <>
                {duplicates ? <>
                    <ItemNavGroup label={member?.id ? `JAMA #${member?.id}` : "Neues Mitglied"}>
                    <div className={styles.somePadding}><h3>Die Person existiert bereits</h3></div>
                    {duplicates.map(d => (
                        <Duplicate selected={d.id === selectedDuplicate?.id} member={d}
                                   onClick={(x) => x.assignable && (x.id !== selectedDuplicate?.id ? setSelectedDuplicate(x) : setSelectedDuplicate(null))}/>
                    ))}
                    {anyAssignable ?
                        <Button width="full" disabled={selectedDuplicate == null} label="Zum Verein hinzufügen"
                                onClick={() => selectedDuplicate && assignMembership(selectedDuplicate)}/>
                        : <Item type="list">Ist bereits Mitglied in einem Verein. Setze dich mit deinem Landesverband in
                            Verbindung.</Item>}
                    </ItemNavGroup>
                </> : <Form onSubmit={onSubmit} className={styles.form}>
                    <div className={styles.header}>
                        <div className={styles.profile}>
                            <AvatarImage src={MemberService.imageUrl(member?.uuid)} className={styles.image}/>
                            {member?.id && <CameraButton
                                className={styles.camera}
                                uuid={member?.uuid}
                                onClick={() => window.location.reload()}
                            />}
                        </div>
                        <div className={styles.top}>

                            <ItemNavGroup label={member?.id ? `JAMA #${member?.id}` : "Neues Mitglied anlegen"}>

                            <Item type="full" label="Nachname" size={12}>
                                <Input onChange={setMemberParam("surname")} value={member?.surname} required
                                       disabled={disabled}/>
                            </Item>
                            <Item type="full" label="Vorname" size={12}>
                                <Input onChange={setMemberParam("firstname")} value={member?.firstname} required
                                       disabled={disabled}/>
                            </Item>
                            {member?.id && <Item type="full" label="Zweiter Vorname" size={12}>
                                <Input onChange={setMemberParam("middlename")} value={member?.middlename}
                                       disabled={false}/>
                            </Item>}
                            </ItemNavGroup>
                        </div>
                    </div>
                    <Item type="full" label="Geb.Datum" size={6}>
                        <DateInput value={PDS(member?.birthdate)} onChange={setMemberParam("birthdate")} required
                                   disabled={disabled}/>
                    </Item>
                    {member?.id && <Item type="full" label="Nationalität" size={6}>

                        <Select type="number" onChange={setMemberParam("nationality")} value={member?.nationality}
                                options={profile?.countries?.map(x => ({
                                    label: x.name,
                                    value: x.id
                                }))}
                                //disabled={disabled}
                        />
                    </Item>}
                    {member?.id && <Item type="full" label="National. bestätigt" size={6}>
                        <DateInput
                            value={PDS(member?.nationchecked)}
                            onChange={setMemberParam("nationchecked")}
                            disabled={role.orgLevel !== 1||disabled}
                        />
                    </Item>}
                    {member?.id && <Item type="full" label="Attest" size={6}>
                        <DateInput
                            value={PDS(member?.attest)}
                            onChange={setMemberParam("attest")}
                            //      disabled={role.orgLevel > 2 || disabled}
                        />
                    </Item>}
                    <Item type="full" label="Geschlecht" size={6}>
                        <Select onChange={setMemberParam("gender")} value={member?.gender} options={[
                            {label: 'M', value: 'm'},
                            {label: 'W', value: 'w'},
                            {label: 'X', value: 'x'}
                        ]} disabled={disabled}/>
                    </Item>
                    {member?.id && <Item type="full" label="Gew.Klasse" size={6}>
                        <Select type="number" onChange={setMemberParam("weightClass")} value={member?.weightClass}
                                options={profile?.weightClasses?.filter(x => {
                                    const age = currentYear - (PDS(member?.birthdate)?.getFullYear() || 0);
                                    return (age >= x.min && age <= x.max && (x.gender === member?.gender || member?.gender === 'x')) || x.max === 0;
                                }).map(x => ({
                                    label: `${x.label}`,
                                    value: x.id
                                }))}/>
                    </Item>}
                    {member?.id && <Item type="full" label="Straße" size={12}>
                        <Input onChange={setMemberParam("street")} value={member?.street} required/>
                    </Item>}
                    {member?.id && <Item type="full" label="Haus.Nr" size={4}>
                        <Input onChange={setMemberParam("houseNo")} value={member?.houseNo} required/>
                    </Item>}
                    {member?.id && <Item type="full" label="Stiege" size={4}>
                        <Input onChange={setMemberParam("stair")} value={member?.stair}/>
                    </Item>}
                    {member?.id && <Item type="full" label="Tür.Nr." size={4}>
                        <Input onChange={setMemberParam("doorNo")} value={member?.doorNo}/>
                    </Item>}
                    {member?.id && <Item type="full" label="PLZ" size={3}>
                        <Input onChange={setMemberParam("zip")} value={member?.zip} required/>
                    </Item>}
                    {member?.id && <Item type="full" label="Ort" size={9}>
                        <Input onChange={setMemberParam("city")} value={member?.city} required/>
                    </Item>}
                    {member?.id && <Item type="full" label="Land" size={6}>
                        <Select type="number" onChange={setMemberParam("country")} value={member?.country}
                                options={profile?.countries?.map(x => ({
                                    label: x.name,
                                    value: x.id
                                }))}/>
                    </Item>}
                    {member?.id && <Item type="full" label="Tel/Mobil" size={6}>
                        <Input onChange={setMemberParam("mobile")} value={member?.mobile} />
                    </Item>}
                    {member?.id && <Item type="full" label="Email" size={6}>
                        <Input onChange={setMemberParam("email")} value={member?.email} />
                    </Item>}
                    {/*{member?.isActive != false ?(*/}
                    {/*    <Button type="submit" width="full" label="Speichern" disabled={loading}/>*/}
                    {/*    ):<></>*/}
                    {/*}*/}
                    {member?.verstorben !== 1 ? (
                        <Button type="submit" width="full" label="Speichern" disabled={loading}/>
                        ) : <></>}
                </Form>}
            </>
        </>
    );
};
