import React, {useEffect, useMemo, useState} from 'react';
import {IMeeting, IMeetingCandidate, IMeetingCandidateParams, weightClassSort} from "../../../api/models";

import {MeetingService} from "../../../api/meeting";
import {MemberService} from "../../../api/member";

import {toaster, toastError} from "../../../core/toaster";
import {ApiError} from "../../../api/http";
import {Loading} from "../../foundation/Loading";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import Toggle from "../../form/Toggle";
import PullToRefresh from "react-simple-pull-to-refresh";
import {BackButton, Button, DateInput, DS, DTS, FD, Input, Item, Segment} from "../../form";
import classNames from "classnames";
import styles from './event_detail_candidates.module.scss'
import {IPageLinkProps} from "../../page.props";
import {useParamId, useParamRole} from "../../useParam";
import { useSearchParams, useNavigate } from "react-router-dom";
import {faCheck, faRemove} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {findInStrangeAgeClassesObject, flattenMeetingAgeClasses} from "./event_detail_edit";
import {v4 as uuidv4} from 'uuid';
import AvatarImage from "../../foundation/AvatarImage";
import {IMAGE_URL} from "../../../api";
import { faSync } from "@fortawesome/free-solid-svg-icons";
// Add these imports at the top with other imports
import { addDays } from 'date-fns';
import moment from "moment/moment";  // For date manipulation


const INVITED   = 'eingeladen';
const ACCEPTED  = 'akzeptiert';
const CANCELED  = 'abgelehnt';
const ATTENDANT = 'anwesend';
const CANDIDATE = 'potentieller Teilnehmer';

/*
2- Nennung mit  (je nach eigenem Alter zum Turnierzeitpunkt) -> sp_allowedAgeClasses(mg_id, meeting_startdate)
 */

const ROLE_JUDOKA = 9;
const ROLE_KR = 14;
const ROLE_CHEFKR = 12;
const ROLE_KATA = 8;
const ROLE_SEARCH = [5,8,9,12,14];


enum EEventStates {
    selection= 1,
    invite = 2,
    accepted = 3,
    attendant = 4,
}

function getStatusEnum(status: string) {
    return [INVITED, ACCEPTED, CANCELED, ATTENDANT, CANDIDATE].indexOf(status)
}

const stateTransitions: {[key: number]: {state: string, icon: IconDefinition}[]} = {
    [EEventStates.selection]: [
    ],
    [EEventStates.invite]: [
        {
            state: INVITED,
            icon: faCheck
        },{
            state: CANCELED,
            icon: faRemove
        }
    ],
    [EEventStates.accepted]: [
        {
            state: ACCEPTED,
            icon: faCheck
        },     {
            state: CANDIDATE,
            icon: faRemove
        }
    ]
}



export const EventDetailCandidates: React.FC<IPageLinkProps> = ({user, profile, refresh, setRefresh}) => {
    const [localRefresh, setLocalRefresh] = useState<number>(0);
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [candidates, setCandidates] = useState<IMeetingCandidate[]>([])
    const [meeting, setMeeting] = useState<IMeeting | null>(null)
    const [search, setSearch] = useState<string>('')
    const [filterState, setFilterState] = useState<number>(1)
    //const [ageClass, setAgeClass] = useState<string>('SENM')
    const [ageClass, setAgeClass] = useState<string | null>(null);

    const meetingId = useParamId();
    const typeId = Number(searchParams.get('mr') || 0);
    // console.log('typeId at component start:', typeId);
    const role = useParamRole(user);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [localSearch, setLocalSearch] = useState<string>('');


    useEffect(() => {
        const fetchMeeting = async () => {
            if (role?.orgId && meetingId) {
                setIsLoading(true);
                setError(null);
                try {
                    const fetchedMeeting = await MeetingService.get(role.orgId, meetingId);
                    setMeeting(fetchedMeeting);
                } catch (error) {
                    // console.error('Error fetching meeting:', error);
                    setError('Failed to fetch meeting details');
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchMeeting();
    }, [role?.orgId, meetingId]);

    //const showAgeClasses = useMemo(() => typeId === ROLE_JUDOKA, [typeId] )
    const showAgeClasses = useMemo(() => {
        // console.log('typeId in useMemo:', typeId);
        return typeId === ROLE_JUDOKA;
    }, [typeId]);
    // console.log('showAgeClasses after useMemo:', showAgeClasses);

    useEffect(() => {
        //console.log('useEffect hook executed');
        setLoading(true);
        if (role != null && meetingId != null && typeId != null) {
            console.log('Calling MeetingService.candidates with:', { orgId: role.orgId, meetingId, typeId });

                            MeetingService.candidates(role.orgId, meetingId, typeId, false) // force refresh
                .then((candidates) => {
                    console.log('candidates received for typeId:', typeId, candidates);
                    if (Array.isArray(candidates) && candidates.length > 0) {
                        setCandidates(candidates);
                    } else {
                        //console.log('No candidates found or response is not an array for typeId:', typeId);
                        setCandidates([]);
                    }
                })
                .catch((e) => {
                    //console.log('error for typeId:', typeId, e);
                    toastError((e as ApiError)?.result?.error);
                    //toastError("Nennng nicht erlaubt");
                })
                .finally(() => setLoading(false));
        } else {
            //console.log('role, meetingId, or typeId is null or undefined');
            setLoading(false);
        }
    }, [user, refresh, localRefresh, role, meetingId, typeId, filterState]);

    const toggleCandidate = async (candidate: IMeetingCandidate, ageClass?: string, weightClass?: string, showAgeClasses?: boolean) => {
        setLoading(true);
        const params = candidate.params as IMeetingCandidateParams[] | undefined;
        const hasChangeParams = params ? params.find(x => (x.AK === ageClass || x.AK === null) && (x.GK === weightClass || x.GK === null)) : undefined;        console.log('hasChangeParams:', hasChangeParams);

        // console.log('#showAgeClasses:', showAgeClasses);
        // console.log('#candidate.params:', params);
        // console.log('#hasChangeParams:', hasChangeParams);

        let hasNoChange: boolean;

        if (showAgeClasses) {
            // For age classes, we check if the weight class matches
            hasNoChange = hasChangeParams?.GK === weightClass;
            // console.log('hasNoChange #1 AKGK:', hasNoChange);
        } else {
            // For non-age classes, we check if the candidate is already added (has params)
            hasNoChange = Array.isArray(params) && params.length > 0;
            // console.log('hasNoChange #2:', hasNoChange);
        }

        const person = `${candidate.surname} ${candidate.firstname}`
        // console.log("hasChangeParams: ", hasChangeParams);
        toaster(async () => {
            await MeetingService.updateCandidate(
                role?.orgId,
                Number(meetingId),
                hasChangeParams?.meeting_mg_id || 0,
                candidate.mgId,
                typeId, // meetingRole
                hasNoChange, // remove: boolean
                undefined, // status?: string,
                ageClass && weightClass ? {AK: ageClass, GK: weightClass} : undefined
            );
        }, {
            success: hasNoChange ? `${person} entfernt` : `${person} updated`,
            failure: "Fehlgeschlagen"
        }, () => {
            setRefresh && setRefresh((refresh || 0) + 1);
            setLocalRefresh(prev => prev + 1); // trigger local refresh
        })
    }

    const isActive = (c: IMeetingCandidate) => {
        const params = c.params as IMeetingCandidateParams[] | undefined;
        if (!params) return false;

        switch (filterState) {
            case EEventStates.selection: // Selektion
                return (c.params as IMeetingCandidateParams[]).length > 0
            case EEventStates.invite: // Einladung
                //return c.status === ATTENDANT || c.status === CANDIDATE || c.status === ACCEPTED;
                return true;
            case EEventStates.attendant: // Anwesenheit
                return c.status === ATTENDANT;
            default:
                return false;
        }
    }

    const isVisible = (c: IMeetingCandidate) => {

        switch (filterState) {
            case 1: // Selektion
                return true
            case 2: // Einladung
                return c.params ? (c.params as IMeetingCandidateParams[]).length > 0 : false
            case 3: // zugesagt/akzeptiert
                return  c.status === ATTENDANT || c.status === CANDIDATE || c.status === ACCEPTED
            // case 4: // zugesagt/akzeptiert
            //     return  c.status === ATTENDANT || c.status === CANDIDATE || c.status === ACCEPTED
            default:
                return false;
        }
    }

    const changeState = (candidate: IMeetingCandidate, state: string) => {
        setLoading(true);
        const hasChangeParams = (candidate.params as IMeetingCandidateParams[]).find(x => x.AK === ageClass);
        // const hasNoChange = hasChangeParams?.GK === weightClass;
        const person = `${candidate.surname} ${candidate.firstname}`
        const timeoutParam = (state === INVITED && timeoutDate)
            ? { invitationTimeoutDate: DS(timeoutDate) }
            : undefined;


        toaster(async () => {
                await MeetingService.updateCandidate(
                    role?.orgId,
                    Number(meetingId),
                    hasChangeParams?.meeting_mg_id || 0,  //assignedMemberId
                    candidate.mgId, //m gId
                    typeId, // meetingRole
                    false, // remove: boolean // false for status toggle!!
                    state, // status?: st                   ring,
                    timeoutParam // params?: IMeetingCandidateParams
                );
        }, {
            success:  `Status von ${person} auf ${state} geändert`,
            failure: "Fehlgeschlagen"
        }, () => {
            setRefresh && setRefresh((refresh || 0) + 1);
            //setLoading(false)
        })
    }

    const filter = useMemo(() => {
        return candidates.filter(x =>
                (x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    x.surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    `${x.mgId}`.includes(search))
            // && (filterState === 0 || (filterState === 1 ? x.assignedMgId != null : filterState === 2 ? x.assignedMgId == null && (x.outError?.length || 0) === 0 : (x.outError?.length || 0) === 0)))
        );
    }, [candidates, search]);

    const ageClasses = useMemo(() => profile.weightClasses
        .filter((x, i, a) => {
            const el = a?.find(y => y?.short === x?.short);
            if (el)
                return a.indexOf(el) === i
            return false
        })
        .sort((a,b) => a.gender.localeCompare(b.gender) * 100 + a.short.localeCompare(b.short))
    , [profile])

    const lookupArray = ["U8M", "U10M", "U12M", "U14M", "U16M", "U18M", "U21M", "U23M", "SENM",
        "U8F", "U10F", "U12F", "U14F", "U16F", "U18F", "U21F", "U23F", "SENF"];

    interface Ages {
        m: string[];
        w: string[];
    }

    const flattenAndSortMeetingAgeClasses = (ages: Ages) => {
        const allAges = [...ages.m, ...ages.w];

        return allAges.sort((a, b) => {
            return lookupArray.indexOf(a) - lookupArray.indexOf(b);
        });
    };

    const ageClassOptions = useMemo(() => [
        { label: "Alle", value: null as string|null}
    ].concat(
        meeting?.ages_nennbar
            ? flattenAndSortMeetingAgeClasses(meeting.ages_nennbar).map(x => ({ label: x, value: x }))
            : ageClasses.map(x => ({ label: x.short, value: x.short }))
    ), [ageClasses, meeting]);

    interface MeetingRole {
        id: number;
        name: string;
        param?: MeetingRoleParam
    }

    interface MeetingRoleParam {
        AK?: string;
        GK?: string;
        funk_id?: number[];
        ausb_id?: number[];
        lang?: any;
        license?: string;
    }

    const getMeetingRoleNameById = (roles: MeetingRole[], id: number): string | undefined => {
        const role = roles.find(role => role.id === id);
        return role ? role.name : undefined;
    };

   const getMeetingRoleParamById = (roles: MeetingRole[], id: number): MeetingRoleParam | undefined => {
        const role = roles.find(role => role.id === id);
        return role ? role.param : undefined;
    };

    const meetingRoleName = getMeetingRoleNameById(profile.meetingRoles, typeId);
    const meetingRoleParam = getMeetingRoleParamById(profile.meetingRoles, typeId);
    //console.log('filteredRoles:', meetingRoleName);
    const [results, setResults] = useState<IMeetingCandidate[] | null>(null);

    const encodeSearchString = (search: string): string => {
        return search.trim().replace(/\s+/g, '+');
    };

    const handleSearch = async (): Promise<IMeetingCandidate[]> => {
        if (search && role?.orgId) {
            setLoading(true);
            try {
                const encodedSearch = encodeSearchString(search);
                // console.log('Encoded search string:', encodedSearch); // Add this line
                const results = await MemberService.searchMembersByNameAtDate(role?.orgId, encodedSearch, DS(meeting?.startdate)|| DS(new Date()));
                //console.log('+++DS(new Date):', DS(new Date())); // Add this line
                if (results) {
                    // console.log('Processed results:', Array.isArray(results) ? results : [results]);
                    return Array.isArray(results) ? results : [results];
                } else {
                    // console.log('No results found');
                    return [];
                }
            } catch (e) {
                // console.error('Search error:', e);
                toastError((e as ApiError)?.result?.error);
                return [];
            } finally {
                setLoading(false);
            }
        } else {
            // console.log('Search not performed: empty search or missing orgId');
            return [];
        }
    };

    useEffect(() => {
        // console.log('Candidates state updated:');
    }, [candidates]);

    const setCandidatesWithLog = (newCandidates: IMeetingCandidate[]) => {
        // console.log('setCandidates called with:', newCandidates);
        setCandidates(newCandidates);
    };

    useEffect(() => {
        // console.log('Search effect running, search:', search);
        if (!meetingRoleParam?.funk_id || !meetingRoleParam?.ausb_id) {
            if (search.length > 2) {
                // console.log('Triggering search for:', search);
                setCandidates([]); // Clear previous results
                const debounce = setTimeout(() => {
                    handleSearch().then((results) => {
                        // console.log('Search results received, about to update state:', results);
                        setCandidates(results); // Update candidates with search results
                        // console.log('setCandidates called with:', results);
                    });
                }, 300);
                return () => clearTimeout(debounce);
            } else {
                // console.log('Clearing candidates due to short search');
                setCandidates([]);
            }
        } else {
            // console.log('Not searching due to meetingRoleParam');
        }
    }, [search, meetingRoleParam]);

    const handleManualRefresh = async () => {
        if (role?.orgId && meetingId != null && typeId != null) {
            setLoading(true);
            try {
                // console.log('Manually refreshing candidates...');
                const refreshedCandidates = await MeetingService.candidates(role.orgId, meetingId, typeId, true);
                if (Array.isArray(refreshedCandidates) && refreshedCandidates.length > 0) {
                    setCandidates(refreshedCandidates);
                    console.log('Candidates refreshed successfully: ', refreshedCandidates.length);
                } else {
                    // console.log('No candidates found or response is not an array');
                    setCandidates([]);
                }
            } catch (e) {
                // console.error('Error refreshing candidates:', e);
                toastError((e as ApiError)?.result?.error || "Failed to refresh candidates");
            } finally {
                setLoading(false);
            }
        } else {
            // console.error('Missing required data for refresh');
            toastError("Unable to refresh: Missing required data");
        }
    };

    const filterCandidates = useMemo(() => {
        return candidates.filter(c => {
            if (!localSearch) return true;
            const searchTerm = localSearch.toLowerCase();
            return (
                c.firstname?.toLowerCase()?.includes(searchTerm) ||
                c.surname?.toLowerCase()?.includes(searchTerm) ||
                `${c.mgId}`.includes(searchTerm)
            );
        });
    }, [candidates, localSearch]);

    const filteredCandidates = useMemo(() => {
        let result;
        if (meetingRoleParam?.funk_id || meetingRoleParam?.ausb_id) {
            result = candidates.filter(c => isVisible(c));

            if (ROLE_SEARCH.includes(typeId)) {  // Changed this condition
                // Apply local search filter for ROLE_SEARCH
                result = result.filter(c => {
                    if (!localSearch) return true;
                    const searchTerm = localSearch.toLowerCase();
                    return (
                        c.firstname?.toLowerCase()?.includes(searchTerm) ||
                        c.surname?.toLowerCase()?.includes(searchTerm) ||
                        `${c.mgId}`.includes(searchTerm)
                    );
                });
            }

            if (showAgeClasses) {
                result = result.filter(c => {
                    const ageClassMatches = ageClass === null || findInStrangeAgeClassesObject(ageClass, c.allowed).length > 0;
                    return ageClassMatches;
                });
            }
        } else {
            result = candidates.filter(isVisible);
        }

        return result;
    }, [ageClass, isVisible, showAgeClasses, candidates, meetingRoleParam, search, localSearch, typeId]);

// Define fixed min and max dates
    const minAllowedDate = moment().toDate();
    const maxAllowedDate = meeting?.startdate ? new Date(meeting.startdate) : new Date();

// Set initial states with defaults within allowed range
    const [timeoutDate, setTimeoutDate] = useState<Date|null>(maxAllowedDate);
// Let's add a useEffect to update timeoutDate when meeting data loads
    useEffect(() => {
        if (meeting?.startdate) {
            setTimeoutDate(new Date(meeting.startdate));
        }
    }, [meeting?.startdate]);

    // console.log('meeting?.name:', meeting?.name)
    // console.log('ageClass::', ageClass);
    return <>
        {loading && <Loading/>}
        <BackButton label={'Zurück'}/>

        <Button
            className={styles.refreshButton}
            width={'full'}
            label="Refresh"
            onClick={handleManualRefresh}
        />
        <ItemNavGroup label={`${meeting?.name || '--'}`}>
            <PullToRefresh onRefresh={async () => setRefresh && setRefresh((refresh || 0) + 1)}
                           onFetchMore={async () => {
                               console.log('more')
                           }}>

                <>
                    <h2 className={styles.roleName}>{filteredCandidates.length} {meetingRoleName}</h2>
                    {!ROLE_SEARCH.includes(typeId) &&
                        <Item type="form" className={`${styles.search}`}>
                            <Input
                                value={search}  // Original API search
                                onChange={setSearch}
                                className={styles.name}
                                placeholder="Suche im System nach Familienname Vorname"
                            />
                        </Item>
                    }
                    {ROLE_SEARCH.includes(typeId) &&
                        <Item type="form" className={`${styles.search}`}>
                            <Input
                                value={localSearch}  // New local search
                                onChange={setLocalSearch}
                                className={styles.name}
                                placeholder="Suche in den aufgelisteten Personen"
                            />
                        </Item>
                    }
                    {((typeId == ROLE_JUDOKA && role?.orgLevel == 3) )  &&
                        <Item type={"full"}>
                            <Segment
                                onChange={setFilterState} value={filterState}
                                options={[
                                    {label: 'zeige alle', value: 1},
                                    {label: 'zeige nur ausgewählte', value: 2},
                                    // {label: 'Anwesenheit', value: 3}
                                ]}
                            />
                        </Item>
                    }
                    {((typeId != ROLE_JUDOKA ) &&
                        <Item type={"full"}>
                            <Segment
                                onChange={setFilterState} value={filterState}
                                options={[
                                    {label: 'zeige alle', value: 1},
                                    {label: 'zeige nur ausgewählte', value: 2},
                                    {label: 'Zugesagte', value: 3},
                                    // {label: 'Anwesenheit', value: 4}
                                ]}
                            />
                            {filterState === EEventStates.invite ? (
                                <div className={styles.dateInput}>
                                    <Button
                                    className={styles.button}
                                    width={'full'}
                                    label="Alle Einladen"
                                    onClick={() => {
                                        // Filter candidates that aren't invited yet
                                        filteredCandidates
                                            .filter(c => c.status !== 'eingeladen')
                                            .forEach(c => {
                                                changeState(c, 'eingeladen');
                                            });
                                    }}
                                />

                                    <label>Rückmeldung erwartet bis (spätestens bis zum Start):</label>
                                    <div className={styles.invitationtimeout}>
                                        <DateInput
                                            value={timeoutDate}
                                            onChange={(date: Date | null) => setTimeoutDate(date)}
                                            minDate={minAllowedDate}
                                            maxDate={maxAllowedDate}
                                            placeholderText="Datum..."
                                        />
                                    </div>
                                </div>
                            ) : ''}
                        </Item>
                    )}
                    {showAgeClasses && <Item type="full">
                        <Segment value={ageClass} onChange={setAgeClass} options={ageClassOptions}/>
                    </Item>
                    }

                    {filteredCandidates.length > 0 ? filteredCandidates.sort((a, b) => a.surname.localeCompare(b.surname)).map(c => (
                            //{ (c.params && role?.orgLevel == 3
                        <Item
                            type={"full"}
                            key={`candidate-${c.mgId}`}
                                        // label={`filterState: ${filterState}`}
                            onClick={filterState === EEventStates.selection && !showAgeClasses ?
                                () => toggleCandidate(c, undefined, undefined, typeId == ROLE_JUDOKA) :
                                undefined
                            }
                        >
                            <div className={classNames(
                                styles.candidate,
                                isActive(c) && styles.active,
                                c.judocard == null && styles.inactive,
                            )}>
                                <div className={`${styles.imageContainer} ${ROLE_JUDOKA == typeId && styles.imageContainer2}`}>
                                    <AvatarImage
                                        className={styles.image}
                                        src={IMAGE_URL + c?.uuid + `?v=${(Date.now() / 1000000).toFixed(0)}`}
                                    />
                                </div>
                                <div className={styles.rightSide}>
                                    <div className={styles.header}>
                                        <div className={styles.headerText}>
                                            <strong>{c.surname} {c.firstname} / {c.gender} / {c.mgId}</strong> / {c.orgName}

                                        </div>
                                    </div>
                                    <div className={styles.info}>
                                        <div className={styles.buttons}>
                                            <>
                                                {/*{(() => {*/}
                                                {/*    console.log('##Current filterState:', filterState);*/}
                                                {/*    console.log('##Current typeId:', typeId);*/}
                                                {/*    console.log('##Available transitions:', stateTransitions[filterState]);*/}
                                                {/*})()}*/}

                                                {ROLE_JUDOKA !== typeId && stateTransitions[filterState].map(x => (
                                                    <Button
                                                        key={uuidv4()}
                                                        className={styles.button}
                                                        onClick={(e) => {
                                                            e.stopPropagation();  // Stop the event from bubbling up
                                                            changeState(c, x.state);
                                                        }}>
                                                        <FontAwesomeIcon icon={x.icon}/>
                                                    </Button>
                                                ))}
                                            </>
                                        </div>
                                        <div className={styles.carddata}>
                                            {ROLE_JUDOKA == typeId ? (
                                                <>
                                                    {"Geb.Datum: "}<strong>{FD(c.birthdate)}</strong>
                                                </>
                                            ) : (
                                                <>
                                                    {"Jahrgang: "}<strong>{FD(c.birthdate)?.toString()?.match(/\d{4}$/)?.[0] || ' '}</strong>
                                                </>
                                            )}
                                            <br/>

                                            gesuchte Rolle: <strong>{c.role}</strong>
                                            {ROLE_JUDOKA == typeId && (
                                                <div className={styles.assignedAkGk}>
                                                    genannte Alters- und
                                                    Gewichtsklassen: <strong>{c.params && ((c.params as IMeetingCandidateParams[]).sort((a, b) => weightClassSort.indexOf(a.GK || '') - weightClassSort.indexOf(b.GK || '')).map(x => x?.AK ? `${x?.AK}/${x?.GK}` : '').join(' '))}</strong>
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            className={styles.licence}>JudoCard: <strong>{c.judocard ? c.judocard : 'Keine JC'}</strong>
                                        </div>
                                    </div>

                                    {showAgeClasses && ageClass !== null && findInStrangeAgeClassesObject(ageClass, c.allowed).length > 0 && (
                                        <Segment
                                            onChange={(v: any) => ageClass !== null ? toggleCandidate(c, ageClass, v, showAgeClasses) : undefined}
                                            className={styles.akgkselector}
                                            //value={(c.params as IMeetingCandidateParams[]).find(x => x.AK === ageClass)?.GK}
                                            value={(c.params as IMeetingCandidateParams[] || [])
                                                .filter(x => x.AK === ageClass)
                                                .map(x => x.GK)
                                            }
                                            options={findInStrangeAgeClassesObject(ageClass, c.allowed)
                                                ?.sort((a, b) => weightClassSort.indexOf(a) - weightClassSort.indexOf(b))
                                                ?.map(x => ({
                                                    label: x,
                                                    value: x
                                                }))
                                            }
                                        />
                                    )}

                                    {((c.outError?.length || 0) > 0) && (<div className={styles.error}>
                                        {c?.outError?.map(x => x.message).join(' | ')}
                                    </div>)}
                                </div>
                                {ROLE_JUDOKA !== typeId &&
                                <div className={styles.statusIndicator}>
                                    <div className={classNames(
                                        styles.light,
                                        c.status === 'abgelehnt' ? styles.redActive : styles.redInactive
                                    )}>
                                        <span className={styles.statusText}>
                                            {c.status === 'abgelehnt' ? c.status : ''}
                                        </span>
                                    </div>
                                    <div className={classNames(
                                        styles.light,
                                        c.status === 'eingeladen' ? styles.yellowActive : styles.yellowInactive
                                    )}>
                                        <span className={styles.statusText}>
                                            {c.status === 'eingeladen' ? c.status : ''}
                                        </span>
                                    </div>
                                    <div className={classNames(
                                        styles.light,
                                        (c.status === 'akzeptiert') ? styles.greenActive : styles.greenInactive
                                    )}>
                                        <span className={styles.statusText}>
                                            {c.status === 'akzeptiert' ? c.status : ''}
                                        </span>
                                    </div>
                                    <div className={classNames(
                                        styles.light,
                                        (c.status === 'anwesend') ? styles.blueActive : styles.blueInactive
                                    )}/>
                                        <span className={styles.statusText}>
                                            {c.status === 'anwesend' ? c.status : ''}
                                        </span>
                                </div>}
                            </div>
                        </Item>
                    )) : <h3 className={styles.center}>Keine Kandidaten verfügbar</h3>}
                </>
            </PullToRefresh>
        </ItemNavGroup>
    </>
};

