import React, { useState, useCallback, useMemo } from "react";
import {Link, useSearchParams} from "react-router-dom";
import { KyuDetailEdit } from "./kyu_detail_edit";
import { KyuDetailNew } from "./kyu_detail_new";
import { KyuDetailCandidates } from "./kyu_detail_candidates";
import { KyuDetailApprove } from "./kyu_detail_approve";
import { IPageLinkProps } from "../../page.props";
import { IKyuExam } from "../../../api/models";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {FD, Item} from "../../form";
import {Row} from "../../form/row";

import styles from "./kyu_detail.module.scss"



interface ExtendedPageLinkProps extends IPageLinkProps {
    refresh?: number;
    setRefresh?: React.Dispatch<React.SetStateAction<number>>;
    triggerRefresh: () => void;
    filteredExams?: IKyuExam[];  // Add this
    dateFrom?: Date | null;      // Add this
    dateTo?: Date | null;        // Add this
    role: {
        orgLevel: number;
        orgId: number;
        funkMgId: number;
    };
}

export const KyuDetail: React.FC<ExtendedPageLinkProps> = ({
                                                               role,
                                                               filteredExams,
                                                               dateFrom,
                                                               dateTo,
                                                               triggerRefresh,
                                                               ...props
                                                           }) => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get('t');
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [candidateToggled, setCandidateToggled] = useState(false);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/kyu`;
    const childProps = {
        ...props,
        refresh: refreshCounter,
        setRefresh: setRefreshCounter,
        triggerRefresh,
        candidateToggled,
        setCandidateToggled,
        role
    };

    const [sortField, setSortField] = useState<'orgName' | 'examCount' | 'participantCount'>('orgName');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

// Add sort handler
    const handleSort = (field: 'orgName' | 'examCount' | 'participantCount') => {
        if (sortField === field) {
            // If same field clicked, toggle direction
            setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
        } else {
            // If new field, set it and default to ascending
            setSortField(field);
            setSortDirection('asc');
        }
    };

    // Calculate aggregates
    const aggregates = useMemo(() => {
        if (!filteredExams || filteredExams.length === 0) {
            //console.log('No filtered exams available');
            return [];
        }

        const aggregateMap = new Map<string, {
            orgId: number,
            orgName: string,
            examCount: number,
            participantCount: number
        }>();

        filteredExams.forEach(exam => {
            const key = exam.orgId.toString();
            const current = aggregateMap.get(key) || {
                orgId: exam.orgId,
                orgName: exam.org || 'Unknown',
                examCount: 0,
                participantCount: 0
            };

            current.examCount++;
            current.participantCount += exam.items || 0;
            aggregateMap.set(key, current);
            //console.log(`Updated aggregate for org ${key}:`, current);
        });

        const result = Array.from(aggregateMap.values())
            .sort((a, b) => b.participantCount - a.participantCount);
        //console.log('Final aggregates:', result);
        return result;
    }, [filteredExams]);

    // Update the sorting in aggregates
    const sortedAggregates = useMemo(() => {
        return [...aggregates].sort((a, b) => {
            const direction = sortDirection === 'asc' ? 1 : -1;
            switch (sortField) {
                case 'orgName':
                    return direction * a.orgName.localeCompare(b.orgName);
                case 'examCount':
                    return direction * (a.examCount - b.examCount);
                case 'participantCount':
                    return direction * (a.participantCount - b.participantCount);
                default:
                    return 0;
            }
        });
    }, [aggregates, sortField, sortDirection]);

    return (
        <>
            {/* Render the appropriate component based on the 'page' parameter */}
            {page === 'edit' && <KyuDetailEdit {...childProps} />}
            {(page === 'new' || page === 'delete') && <KyuDetailNew {...childProps} />}
            {page === 'candidates' && <KyuDetailCandidates {...childProps} />}
            {page === 'approve' && <KyuDetailApprove {...childProps} />}
            {!page && (
                <>
                {role.orgLevel == 3 && (
                    <div className={styles.headerempty}>
                        <p>Bitte wähle links eine KYU-Prüfung oder erstelle <Link to={`${gotoUrl}/0?t=new`}> hier eine neue KYU-Prüfung.</Link>
                        </p>
                    </div>
                )}
                    {/* Add aggregates section */}
                    <ItemNavGroup
                        label={`Statistik ${dateFrom ? FD(dateFrom) : '---'} bis ${dateTo ? FD(dateTo) : '---'}`}>
                        {/* Total row */}
                        {/*<Row type="header">*/}
                        {/*    <Item type="split" size={5}>*/}
                        {/*        <div className={styles.children}>Gesamt:</div>*/}
                        {/*    </Item>*/}
                        {/*    <Item type="split" size={3}>*/}
                        {/*        <div className={styles.children}>*/}
                        {/*            {aggregates.reduce((sum, agg) => sum + agg.examCount, 0)}*/}
                        {/*        </div>*/}
                        {/*    </Item>*/}
                        {/*    <Item type="split" size={3}>*/}
                        {/*        <div className={styles.children}>*/}
                        {/*            {aggregates.reduce((sum, agg) => sum + agg.participantCount, 0)}*/}
                        {/*        </div>*/}
                        {/*    </Item>*/}
                        {/*</Row>*/}

                        {/*/!* Header row *!/*/}
                        {/*<Row type="header">*/}
                        {/*    <Item*/}
                        {/*        type="split"*/}
                        {/*        size={5}*/}
                        {/*        onClick={() => handleSort('orgName')}*/}
                            {/*        className={styles.sortableHeader}*/}
                        {/*    >*/}
                        {/*        <div className={styles.children}>*/}
                        {/*            Verein {sortField === 'orgName' && (sortDirection === 'asc' ? '↑' : '↓')}*/}
                        {/*        </div>*/}
                        {/*    </Item>*/}
                        {/*    <Item*/}
                        {/*        type="split"*/}
                        {/*        size={3}*/}
                        {/*        onClick={() => handleSort('examCount')}*/}
                        {/*        className={styles.sortableHeader}*/}
                        {/*    >*/}
                        {/*        <div className={styles.children}>*/}
                        {/*            Prüf. {sortField === 'examCount' && (sortDirection === 'asc' ? '↑' : '↓')}*/}
                        {/*        </div>*/}
                        {/*    </Item>*/}
                        {/*    <Item*/}
                        {/*        type="split"*/}
                        {/*        size={3}*/}
                        {/*        onClick={() => handleSort('participantCount')}*/}
                        {/*        className={styles.sortableHeader}*/}
                        {/*    >*/}
                        {/*        <div className={styles.children}>*/}
                        {/*            Teiln. {sortField === 'participantCount' && (sortDirection === 'asc' ? '↑' : '↓')}*/}
                        {/*        </div>*/}
                        {/*    </Item>*/}
                        {/*</Row>*/}

                        {/* Data rows */}
                        {/*{sortedAggregates.map(agg => (*/}
                        {/*    <Row key={agg.orgId}>*/}
                        {/*        <Item type="split" size={5}>*/}
                        {/*            <div className={styles.children}>{agg.orgName} /ID:{agg.orgId}</div>*/}
                        {/*        </Item>*/}
                        {/*        <Item type="split" size={3} className={styles.children}>*/}
                        {/*            {agg.examCount}*/}
                        {/*        </Item>*/}
                        {/*        <Item type="split" size={3}>*/}
                        {/*            {agg.participantCount}*/}
                        {/*        </Item>*/}
                        {/*    </Row>*/}
                        {/*))}*/}
                        <table className={styles.excelTable}>
                            {/* Header row */}
                            <thead>
                            <tr>
                                <th onClick={() => handleSort('orgName')}>
                                    Verein {sortField === 'orgName' && (sortDirection === 'asc' ? '↑' : '↓')}
                                </th>
                                <th onClick={() => handleSort('examCount')}>
                                    Prüfungen {sortField === 'examCount' && (sortDirection === 'asc' ? '↑' : '↓')}
                                </th>
                                <th onClick={() => handleSort('participantCount')}>
                                    Teilnehmer {sortField === 'participantCount' && (sortDirection === 'asc' ? '↑' : '↓')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* Total row */}
                            <tr>
                                <td>Gesamt:</td>
                                <td>{aggregates.reduce((sum, agg) => sum + agg.examCount, 0)}</td>
                                <td>{aggregates.reduce((sum, agg) => sum + agg.participantCount, 0)}</td>
                            </tr>
                            {/* Data rows */}
                            {sortedAggregates.map(agg => (
                                <tr key={agg.orgId}>
                                    <td>{agg.orgName}</td>
                                    <td>{agg.examCount}</td>
                                    <td>{agg.participantCount}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div>{'\u00A0'}</div>
                    </ItemNavGroup>
                </>
            )}
        </>
    );
};

export default KyuDetail;