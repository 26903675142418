import React, {useState, useCallback, useEffect} from 'react';
import {withDesktop} from "../../api/withDesktop";
import {Layout} from "../../components/foundation/layout";
import Kyu from "../../components/admin/kyu/kyu";
import {IPageProps} from "../../components/page.props";
import {useParamRole} from "../../components/useParam";
import KyuDetail from "../../components/admin/kyu/kyu_detail";
import printStyles from '../../theme/print.module.scss';
import { IKyuExam } from "../../api/models";

export const DesktopKyuPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const [refresh, setRefresh] = useState<number>(0);
    const [filteredExams, setFilteredExams] = useState<IKyuExam[]>([]);
    const [dateFrom, setDateFrom] = useState<Date|null>(null);
    const [dateTo, setDateTo] = useState<Date|null>(null);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/kyu`;

    const triggerRefresh = useCallback(() => {
        setRefresh(prev => prev + 1);
    }, []);

    const handleExamsFiltered = useCallback((exams: IKyuExam[], from: Date|null, to: Date|null) => {
        // console.log('DesktopKyuPage received:', {
        //     exams,
        //     from,
        //     to
        // });
        setFilteredExams(exams);
        setDateFrom(from);
        setDateTo(to);
    }, []);

    // console.log('DesktopKyuPage rendering with:', {
    //     filteredExams,
    //     dateFrom,
    //     dateTo
    // });

    return (
        <Layout
            listClassName={printStyles['hide-for-print']}
            list={
                <Kyu
                    {...props}
                    gotoUrl={gotoUrl}
                    refresh={refresh}
                    triggerRefresh={triggerRefresh}
                    onExamsFiltered={handleExamsFiltered}
                />
            }
            page={
                <KyuDetail
                    {...props}
                    gotoUrl={gotoUrl}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    triggerRefresh={triggerRefresh}
                    filteredExams={filteredExams}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    role={role}
                />
            }
        />
    );
};

export default withDesktop(DesktopKyuPage);